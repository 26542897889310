import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isMobile: false,
    showSwiper: false
  },
  getters: {
  },
  mutations: {
    setIsMobile(state, isMobile) {
      state.isMobile = isMobile;
    },
    setShowSwiper(state, showSwiper) {
      state.showSwiper = showSwiper;
    }
  },
  actions: {
  },
  modules: {
  }
})
