<!--  -->
<template>
  <div
    class="mobile-list"
    @touchstart="handleTouchStart"
    @touchend="handleTouchEnd"
  >
    <div class="type" v-for="item in list" :key="item.title_en">
      <div class="title">
        <p>{{ item.title_en }}</p>
        <p>{{ item.title_cn }}</p>
      </div>
      <div class="data">
        <div
          :class="['item', cover.flex == 1 ? 'flex1' : 'flex2','reveal-bottom',]"
          v-for="cover in item.data"
          :key="cover.img"
          @click.stop="goProductPage(cover.img,$event)"
        >
          <div
            class="img-wrap"
            :style="{ width: cover.imgWidth ? cover.imgWidth : '80%' }"
          >
            <img :src="cover.cover" alt="" oncontextmenu="return false;" :style="showMask ? 'opacity: 0.5' : 'opacity: 1' "/>
            <div
              :class="['info', cover.infoStyle ? cover.infoStyle : '',]"
              v-show="showMask"
            >
              <div :class="['info-title', cover.textStyle]">
                <p>{{ cover.title }}</p>
                <p>{{ cover.title2 }}</p>
              </div>
              <div class="isEmpty" v-if="cover.isEmpty">（空箱）</div>
              <ul class="info-text">
                <li v-for="infoItem in cover.info" :key="infoItem">
                  {{ infoItem }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="type">
      <div class="title">
        <p>ACCESSORIES</p>
        <p>配件</p>
      </div>
      <div class="acces">
        <div class="acce-data" @click="goProductPage(accessories[0].img,$event)">
          <div class="item reveal-bottom">
            <div class="img-wrap">
              <img :src="accessories[0].cover" alt="" oncontextmenu="return false;" :style="showMask ? 'opacity: 0.5' : 'opacity: 1' "/>
              <div class="info" v-show="showMask">
                <div class="info-title">
                  <p>{{ accessories[0].title }}</p>
                </div>
                <ul :class="['info-text','p30']">
                  <li v-for="i in accessories[0].info" :key="i">
                    {{ i }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="acce-data" @click="goProductPage(accessories[1].img,$event)">
          <div class="item reveal-bottom">
            <div class="img-wrap">
              <img :src="accessories[1].cover" alt="" oncontextmenu="return false;" :style="showMask ? 'opacity: 0.5' : 'opacity: 1' " />
              <div class="info" v-show="showMask">
                <div class="info-title">
                  <p>{{ accessories[1].title }}</p>
                </div>
                <ul class="info-text">
                  <li v-for="i in accessories[1].info" :key="i">
                    {{ i }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="mask2" v-show="showMask"></div> -->
  </div>
</template>

<script>
import scrollReveal from "scrollreveal";

export default {
  data() {
    return {
      list: [
        {
          title_en: "DISTRIBUTION BOX MONITORING WINDOW (TAW80 SERIES)",
          title_cn: "配 电 箱 监 视 窗 口 （ T A W 8 0 系 列 ）",
          data: [
            {
              cover: require("../assets/image/list/peidianxiang1.png"),
              title: "德式防水窗口",
              info: ["A型：不带保护", "B型：带保护"],
              textStyle: "br",
              flex: 2,
              index: 0,
              isEmpty: false,
              img: "peidianxiang1",
            },
            {
              cover: require("../assets/image/list/peidianxiang2.png"),
              title: "豪华防水窗口",
              info: ["C型：带挡板", "D型：不带挡板", "S型：带锁扣"],
              textStyle: "bl",
              flex: 1,
              index: 1,
              isEmpty: false,
              img: "peidianxiang2",
            },
            {
              cover: require("../assets/image/list/peidianxiang3.png"),
              title: "电力仪表窗口",
              info: [],
              textStyle: "p30",
              flex: 1,
              index: 2,
              isEmpty: false,
              img: "peidianxiang3",
            },
            {
              cover: require("../assets/image/list/peidianxiang4.png"),
              title: "塑壳空开窗口",
              info: [],
              textStyle: "p30",
              flex: 1,
              index: 3,
              isEmpty: false,
              img: "peidianxiang4",
            },
            {
              cover: require("../assets/image/list/peidianxiang5.png"),
              title: "86型防水窗口",
              info: [],
              textStyle: "p30",
              flex: 1,
              index: 4,
              isEmpty: false,
              img: "peidianxiang5",
            },
          ],
        },
        {
          title_en: "INDUSTRIAL SOCKET BOX(TDB81 SERIES)",
          title_cn: "工 业 插 座 箱 （ T D B 8 1 系 列 ）",
          data: [
            {
              cover: require("../assets/image/list/gongyechazuoxiang1.png"),
              title: "螺栓",
              title2: "控制箱",
              info: ["螺栓型", "拼装型"],
              textStyle: "p20",
              flex: 1,
              index: 0,
              isEmpty: true,
              img: "gongyechazuoxiang1",
            },
            {
              cover: require("../assets/image/list/gongyechazuoxiang2.png"),
              title: "合页",
              title2: "控制箱",
              info: [],
              textStyle: "bc",
              flex: 1,
              width: "10rem",
              height: "13.69rem",
              index: 1,
              isEmpty: true,
              img: "gongyechazuoxiang2",
            },
            {
              cover: require("../assets/image/list/gongyechazuoxiang3.png"),
              title: "阶梯",
              title2: "插座箱",
              info: [],
              textStyle: "bc",
              flex: 1,
              width: "11.25rem",
              height: "14.38rem",
              index: 2,
              isEmpty: true,
              img: "gongyechazuoxiang3",
            },
            {
              cover: require("../assets/image/list/gongyechazuoxiang4.png"),
              title: "手提",
              title2: "控制箱",

              info: [],
              textStyle: "bc",
              flex: 1,
              width: "13.63rem",
              height: "13.36rem",
              index: 3,
              isEmpty: true,
              img: "gongyechazuoxiang4",
            },
            {
              cover: require("../assets/image/list/gongyechazuoxiang5.png"),
              title: "斜面",
              title2: "控制箱",

              info: [],
              textStyle: "bc",
              flex: 1,
              width: "9.44rem",
              height: "12.75rem",
              index: 4,
              isEmpty: true,
              img: "gongyechazuoxiang5",
            },
            {
              cover: require("../assets/image/list/gongyechazuoxiang6.png"),
              title: "电源",
              title2: "检修箱",
              info: [
                "手提插座箱",
                "壁挂插座箱",
                "拼装插座箱",
                "阶梯插座箱",
                "移动插座箱",
                "金属插座箱",
                "吊装插座箱",
              ],
              textStyle: "lr",
              flex: 1,
              width: "10.88rem",
              height: "15rem",
              index: 5,
              isEmpty: false,
              img: "gongyechazuoxiang6",
            },
          ],
        },
        {
          title_en: "WATERPROOF CONTROL BOX (TDB82 SERIES)",
          title_cn: "防 水 控 制 箱（T D B 8 2 系 列）",
          data: [
            {
              cover: require("../assets/image/product/fangshuikongzhixiang1.png"),
              title: "螺栓",
              title2: "控制箱",
              flex: 1,
              info: ["G：灰色盖", "T：透明盖"],
              textStyle: "br",
              index: 0,
              isEmpty: false,
              img: "fangshuikongzhixiang1",
            },
            {
              cover: require("../assets/image/product/fangshuikongzhixiang2.png"),
              title: "合页",
              title2: "控制箱",
              flex: 1,

              info: ["带锁型", "搭扣型"],
              textStyle: "br",
              index: 1,
              isEmpty: false,
              img: "fangshuikongzhixiang2",
            },
            {
              cover: require("../assets/image/product/fangshuikongzhixiang3.png"),
              title: "中门",
              title2: "控制箱",
              flex: 1,

              info: ["带锁型", "搭扣型"],
              textStyle: "br",
              index: 2,
              isEmpty: false,
              img: "fangshuikongzhixiang3",
            },
            {
              cover: require("../assets/image/product/fangshuikongzhixiang4.png"),
              title: "工地",
              title2: "配电箱",
              info: [],
              textStyle: "bc",
              flex: 1,

              index: 3,
              isEmpty: false,
              img: "fangshuikongzhixiang4",
            },
            {
              cover: require("../assets/image/product/fangshuikongzhixiang5.png"),
              title: "拼装",
              title2: "控制箱",
              flex: 2,

              info: ["G：灰色盖", "T：透明盖"],
              textStyle: "lr",
              index: 4,
              isEmpty: false,
              img: "fangshuikongzhixiang5",
            },
          ],
        },
        {
          title_en: "WATERPROOF JUNCTION BOX (TDB83-86 SERIES)",
          title_cn: "防 水 接 线 盒（T D B 8 3 - 8 6 系 列）",
          data: [
            {
              cover: require("../assets/image/product/fangshuijiexianhe1.png"),
              title: "AG型接线盒",
              info: ["G：灰色盖", "T：透明盖"],
              textStyle: "title-t-c",
              flex: 1,
              imgWidth: "45%",
              index: 0,
              isEmpty: false,
              img: "fangshuijiexianhe1",
            },
            {
              cover: require("../assets/image/product/fangshuijiexianhe2.png"),
              title: "F型接线盒",
              info: ["带耳固定", "不带耳固定"],
              flex: 1,
              textStyle: "br",

              index: 1,
              isEmpty: false,
              img: "fangshuijiexianhe2",
            },
            {
              cover: require("../assets/image/product/fangshuijiexianhe3.png"),
              title: "经济型接线盒",
              info: ["带塞", "不带塞"],
              flex: 1,
              textStyle: "br",
              width: "11.19rem",
              height: "11.19rem",
              index: 2,
              isEmpty: false,
              img: "fangshuijiexianhe3",
            },
            {
              cover: require("../assets/image/product/fangshuijiexianhe4.png"),
              title: "一体端子盒",
              flex: 1,
              info: [],
              textStyle: "bc",
              imgWidth: "50%",
              index: 3,
              isEmpty: false,
              img: "fangshuijiexianhe4",
            },

            {
              cover: require("../assets/image/product/fangshuijiexianhe6.png"),
              title: "合页接线盒",
              info: ["不锈钢搭扣", "塑料搭扣"],
              flex: 1,
              textStyle: "br",
              width: "16rem",
              height: "15rem",
              index: 5,
              isEmpty: false,
              img: "fangshuijiexianhe6",
            },
            {
              cover: require("../assets/image/product/fangshuijiexianhe7.png"),
              title: "铸铝接线盒",
              flex: 1,
              info: [],
              textStyle: "bc",
              imgWidth: "50%",
              index: 6,
              isEmpty: false,
              img: "fangshuijiexianhe7",
            },
            {
              cover: require("../assets/image/product/fangshuijiexianhe5.png"),
              title: "分线",
              title2: "接线盒",
              flex: 2,
              info: [],
              textStyle: "title-t-c",
              width: "33.13rem",
              height: "8.5rem",
              index: 4,
              isEmpty: false,
              img: "fangshuijiexianhe5",
            },
          ],
        },
        {
          title_en: "WATERPROOF BUTTON BOX (TDB87 SERIES)",
          title_cn: "防 水 按 钮 盒（T D B 8 7 系 列）",
          data: [
            {
              cover: require("../assets/image/product/fangshuianniuhe1.png"),
              title: "施耐德按钮盒",
              flex: 1,
              info: [],
              textStyle: "tr",
              imgWidth: "50%",
              index: 0,
              isEmpty: false,
              img: "fangshuianniuhe1",
            },
            {
              cover: require("../assets/image/product/fangshuianniuhe2.png"),
              title: "经济型按钮盒",
              flex: 1,

              info: [],
              textStyle: "tr",
              imgWidth: "50%",
              index: 1,
              isEmpty: false,
              img: "fangshuianniuhe2",
            },
            {
              cover: require("../assets/image/product/fangshuianniuhe3.png"),
              title: "AG型",
              title2: "按钮盒",
              flex: 1,

              info: [],
              textStyle: "tr",
              index: 2,
              isEmpty: false,
              img: "fangshuianniuhe3",
            },
            {
              cover: require("../assets/image/product/fangshuianniuhe4.png"),
              title: "带防尘罩按钮盒",
              flex: 1,
              imgWidth: "60%",
              info: [],
              width: "7.06rem",
              height: "12.81rem",
              index: 3,
              isEmpty: false,
              img: "fangshuianniuhe4",
            },
            {
              cover: require("../assets/image/product/fangshuianniuhe5.png"),
              title: "铸铝按钮盒",
              flex: 1,
              info: [],
              imgWidth: "50%",
              index: 4,
              isEmpty: false,
              img: "fangshuianniuhe5",
            },
            {
              cover: require("../assets/image/product/fangshuianniuhe6.png"),
              title: "高桥按钮盒",
              flex: 1,
              info: [],
              imgWidth: "50%",
              index: 5,
              isEmpty: false,
              img: "fangshuianniuhe6",
            },
            {
              cover: require("../assets/image/product/fangshuianniuhe7.png"),
              title: "带灯按钮盒",
              flex: 1,

              info: [],
              infoStyle: "title-c",
              imgWidth: "110%",
              index: 6,
              isEmpty: false,
              img: "fangshuianniuhe7",
            },
          ],
        },
        {
          title_en: "INDUSTRIAL PLUG AND SOCKET (TDM88 SERIES)",
          title_cn: "工 业 插 头 插 座（T D M 8 8 系 列）",
          data: [
            {
              cover: require("../assets/image/product/gongyechatou1.png"),
              title: "德式型",
              flex: 1,
              infoStyle: "info-t20-l",
              info: [
                "插头",
                "明装插座",
                "连接器(耦合器)",
                "直座",
                "斜座",
                "进线插座",
              ],
              imgWidth: "110%",
              index: 0,
              isEmpty: false,
              img: "gongyechatou1",
            },
            {
              cover: require("../assets/image/product/gongyechatou2.png"),
              title: "经济型",
              flex: 1,
              infoStyle: "info-t20-l",
              info: [
                "插头",
                "明装插座",
                "连接器(耦合器)",
                "直座",
                "斜座",
                "进线插座",
              ],
              imgWidth: "110%",
              index: 1,
              isEmpty: false,
              img: "gongyechatou2",
            },
            {
              cover: require("../assets/image/product/gongyechatou3.png"),
              title: "国标型",
              info: [],
              flex: 1,
              infoStyle: "title-c",
              imgWidth: "65%",
              index: 2,
              isEmpty: false,
              img: "gongyechatou3",
            },
            {
              cover: require("../assets/image/product/gongyechatou4.png"),
              title: "欧标型",
              info: [],
              flex: 1,
              infoStyle: "title-c",
              imgWidth: "65%",
              index: 3,
              isEmpty: false,
              img: "gongyechatou4",
            },
          ],
        },
        {
          title_en: "WATERPROOF DISTRIBUTION BOX (TDB89 SERIES)",
          title_cn: "防 水 配 电 箱 （ T D B 8 9 系 列 ）",
          data: [
            {
              cover: require("../assets/image/product/fangshuipeidianxiang1.png"),
              title: "TD-AG系列",
              info: ["户外配电箱"],
              imgWidth: "110%",
              infoStyle: "info-t-l",
              flex: 1,
              index: 0,
              isEmpty: false,
              img: "fangshuipeidianxiang1",
            },
            {
              cover: require("../assets/image/product/fangshuipeidianxiang2.png"),
              title: "TD-HA系列",
              info: ["户外配电箱"],
              flex: 1,
              imgWidth: "112%",

              infoStyle: "info-t-l",
              index: 1,
              isEmpty: false,
              img: "fangshuipeidianxiang2",
            },
            {
              cover: require("../assets/image/product/fangshuipeidianxiang3.png"),
              title: "TD-HT系列",
              info: ["户外配电箱"],

              infoStyle: "info-t-l",
              flex: 1,
              index: 2,
              isEmpty: false,
              img: "fangshuipeidianxiang3",
            },
            {
              cover: require("../assets/image/product/fangshuipeidianxiang4.png"),
              title: "TD-MCE系列",
              info: ["户外配电箱"],
              infoStyle: "info-t-l",
              flex: 1,
              index: 3,
              isEmpty: false,
              img: "fangshuipeidianxiang4",
            },
            {
              cover: require("../assets/image/product/fangshuipeidianxiang5.png"),
              title: "TD-EX系列",
              title2: "(防爆配电箱)",
              info: ["户外配电箱"],
              imgWidth: "122%",
              infoStyle: "info-t-l",
              flex: 1,
              index: 4,
              isEmpty: false,
              img: "fangshuipeidianxiang5",
            },
            {
              cover: require("../assets/image/product/fangshuipeidianxiang5.png"),
              title: "室内",
              title2: "配电箱",
              // imgWidth: '105%',
              infoStyle: "info-t-l",

              info: [],
              flex: 1,
              index: 5,
              isEmpty: false,
              img: "fangshuipeidianxiang6",
            },
          ],
        },
        {
          title_en: "EXPOSED WATERPROOF SOCKET BOX (TDB90 SERIES)",
          title_cn: "明 装 防 水 插 座 盒 （T D B 9 0 系 列）",
          data: [
            {
              cover: require("../assets/image/product/mingzhuangfangshuichazuo1.png"),
              title: "IP44插座盒",
              info: [],
              flex: 1,
              imgWidth: "110%",
              infoStyle: "title-c",
              index: 0,
              isEmpty: false,
              img: "mingzhuangfangshuichazuo1",
            },
            {
              cover: require("../assets/image/product/mingzhuangfangshuichazuo2.png"),
              title: "IP55插座盒",
              info: [],
              flex: 1,
              infoStyle: "title-c",
              index: 1,
              isEmpty: false,
              img: "mingzhuangfangshuichazuo2",
            },
            {
              cover: require("../assets/image/product/mingzhuangfangshuichazuo3.png"),
              title: "IP66",
              title2: "插座盒",
              info: [],
              flex: 1,
              index: 2,
              isEmpty: false,
              img: "mingzhuangfangshuichazuo3",
            },
            {
              cover: require("../assets/image/product/mingzhuangfangshuichazuo4.png"),
              title: "86型",
              title2: "插座盒",
              infoStyle: "info-b10-l",
              info: ["A款", "B款"],
              flex: 1,
              index: 3,
              isEmpty: false,
              img: "mingzhuangfangshuichazuo4",
            },
          ],
        },
      ],
      //配件
      accessories: [
        {
          cover: require("../assets/image/product/peijian1.png"),
          title: "电缆防水接头",
          info: [],
          textStyle: "",
          width: "8.13rem",
          height: "10rem",
          index: 0,
          isEmpty: false,
          img: "peijian1",
        },
        {
          cover: require("../assets/image/product/peijian2.png"),
          title: "更多配件",
          info: [
            "挂角",
            "防水塞头",
            "电缆接头",
            "塑料钥匙",
            "导轨",
            "窗口支架",
            "C45挡板",
            "蜂窝版",
            "接线端子",
          ],
          textStyle: "",
          width: "",
          height: "",
          index: 1,
          isEmpty: false,
          img: "peijian2",
        },
      ],
      showMask: false,
      scrollReveal: scrollReveal(),
    };
  },
  mounted(){
    // this.scrollReveal.reveal(".reveal-bottom", {
    //   // 动画的时长
    //   duration: 1000,
    //   // 延迟时间
    //   delay: 100,
    //   // 动画开始的位置，'bottom', 'left', 'top', 'right'
    //   origin: "bottom",
    //   // 回滚的时候是否再次触发动画
    //   reset: false,
    //   // 在移动端是否使用动画
    //   mobile: true,
    //   // 滚动的距离，单位可以用%，rem等
    //   distance: "6.25rem",
    //   // 其他可用的动画效果
    //   opacity: 0.5,
    //   easing: "linear",
    // });
  },
  methods: {
    handleTouchStart(e) {
      this.showMask = true;
    },
    handleTouchEnd(e) {
      this.showMask = false;
    },
    goProductPage(img,event){
      
      this.$router.push(`/product?url=${img}`).catch((err) => err);
    },
  },
};
</script>
<style lang='less' scoped>
img {
    pointer-events:none;
  }
.mobile-list {
  padding: 160px 40px;
  .type {
    > .title {
      font-size: 50px;
      margin: 60px 0 10px;
    }
  }

  .data {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .item {
      // margin-right: 20px;
      // margin-bottom: 30px;
    }
    .flex1 {
      flex-basis: calc(33.33% - 80px); /* 减去间距 */
      flex-grow: 1;
      margin: 40px; /* 元素之间的间距 */
      max-width: 33%;
    }
    .flex2 {
      flex-basis: calc(66.66% - 80px); /* 减去间距 */
      flex-grow: 2;
      max-width: 65%;
      margin: 40px; /* 元素之间的间距 */
    }
    .img-wrap {
      position: relative;
      width: 80%;
      margin: auto;
      > img {
        width: 100%;
      }
      .info {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 90;
        .info-title {
          font-family: "思源黑体", sans-serif;
          font-size: 80px;
          line-height: 1;
        }
        .isEmpty {
          font-size: 60px;
        }
        .info-text {
          margin-top: 20px;
          font-size: 36px;
          font-weight: 500;
          line-height: 1.2;
        }
      }
    }
  }

  .acces {
    position: relative;
    .acce-data {
      .img-wrap {
        position: relative;
        .info {
          position: absolute;
          top: 0;
          left: 0;
          
          z-index: 90;
          .info-title {
            font-family: "思源黑体", sans-serif;
            font-size: 80px;
            line-height: 1;
          }
          .info-text {
            margin-top: 20px;
            font-size: 36px;
            font-weight: 500;
            line-height: 1.2;
          }
        }
      }
      &:first-child {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        .img-wrap {
          width: 20%;

          > img {
            width: 100%;
          }
          .info {
            text-align: center;
          }
        }
      }
      &:last-child {
        .img-wrap {
          width: 100%;
          > img {
            width: 100%;
          }
          .info {
            // padding-right: 20%;
            right: 20%;
            text-align: right;
          }
        }
      }
      
    }
  }
}
.mask2 {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #fff;
  opacity: 0.5;
  z-index: 2;
}
.p30 {
  padding: 30px;
  text-align: center;
}
//商品标题靠上居中
.title-t-c {
  text-align: center;
}

//商品标题上下左右居中
.title-c {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  text-align: center;
}
//商品和描述上移20%，描述靠右对齐
.info-t20-l {
  text-align: right;
  transform: translateY(-20%);
}
.info-b10-l {
  text-align: right;
  transform: translateY(10%);
}
//商品标题靠上，描述靠右对齐
.info-t-l {
  text-align: right;
}
//商品描述在右侧
</style>