<!--  -->
<template>
  <div class="home">
    <transition name="fade">
      <div class="mask" v-show="showMask"></div>
    </transition>
    <Head @hideList="hideList" />

    <div class="list-box" ref="listbox_ref">
      <ProductList @changeActive="changeActive" data="data1" v-if="!isMobile" />
      <MobileList v-else />
    </div>

    <ScrollTop ref="scroll_top_ref"/>

    
  </div>
</template>

<script>
import Head from "../components/Head.vue";
import ProductList from "../components/ProductList.vue";
import MobileList from "../components/ProductList2.vue";
import ScrollTop from "../components/ScrollTop";
import CollapseTransition from "../utils/collapseTransition";
import { gsap } from "gsap";
export default {
  components: {
    ProductList,
    ScrollTop,
    Head,
    MobileList,
    "collapse-transition": CollapseTransition,
  },
  data() {
    return {
      isMobile: false,
      showMask: false,
      showList: true,
      missList: false,
      listTween: null,
    };
  },
  mounted() {
    this.setIsMobile();
    window.addEventListener('resize',this.setIsMobile);
  },
  
  destroyed() {
    window.removeEventListener('resize',this.setIsMobile)
  },
  methods: {
    
    setIsMobile() {
      setTimeout(() => {
        this.isMobile = window.innerWidth <= 1024 ? true : false;
      }, 100);
    },
    changeActive(flag) {
      this.showMask = flag;
    },
    hideList(showInfo,time) {
      this.showList = !showInfo;
      this.listTween = new gsap.timeline();
      if (this.showList) {
        this.listTween
          .to(".list-box", {
            display: "block",
            
          })
          .to(".list-box", {
            y: 0,
            duration: 1,
            delay: time,
            onComplete: () => {
              const el = this.$refs.listbox_ref;
              el.style = "";
            },
          });
      } else {
        this.listTween
          .to(".list-box", {
            y: "100vh",
            duration: 1,
          })
          .to(".list-box", {
            display: "none",
            delay: 0.5
          });
      }
    },
  },
};
</script>
<style lang='less' scoped>
.home {
  // padding: 0 0 50px 0;
  .mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    opacity: 0.5;
    z-index: 99;
  }
  .fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    transition: opacity 0.2s;
  }
  .fade-leave-to {
    opacity: 0;
  }
  .fade-leave-active {
    transition: opacity 0.2s;
  }

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // position: fixed;
    height: 100px;
    margin-bottom: 30px;
    .logo1 {
      height: 37px;
    }
    .r-logo {
      cursor: pointer;
      padding-right: 220px;
      .logo2 {
        height: 44px;
      }
      .logo-text {
        font-size: 40px;
        font-weight: 500;
      }
    }
  }

  
}


</style>