<!--  -->
<template>
  <div class="copy">
    <div class="left">
      <span @click="goHome" :class="[showSwiper ? 'textColor' : '', 'fontSize']"
        >© ZHEJIANG TIANDAO ELECTRIC CO.,LTD</span
      >
      <img
        src="../assets/image/home/top.png"
        alt=""
        class="top"
        @click="toTop"
        v-show="!isMobile"
      />
    </div>
    <div class="right-warp">
      <div
        :class="['right', showSwiper ? 'textColor' : '']"
        @click="goLogoPage"
         ref="right_ref"
      >
        <div class="scroll-wrap">
          <div class="scroll1 fontSize">
            2008, 2023 LOGOSKY &amp; MAINEIKAS &amp;
            MFNNFKES&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CONTACT
            US: SALE@LOGOSKY.COM.CN&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div class="fontSize" style="color: #22b473">
              Tel&nbsp;&amp;&nbsp;WeChat：13017811966
            </div>
          </div>
          <!-- <div class="scroll2 fontSize">
            2008, 2023 LOGOSKY &amp; MAINEIKAS &amp;
            MFNNFKES&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CONTACT
            US: SALE@LOGOSKY.COM.CN&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div class="fontSize" style="color: #22b473">
              Tel&nbsp;&amp;&nbsp;WeChat：13017811966
            </div>
          </div> -->
        </div>
        <a class="ip" href="https://beian.miit.gov.cn/">浙ICP备20002591号</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { gsap } from "gsap";
export default {
  data() {
    return {
      scroll1: null,
      scroll2: null,
      isMobile: null,
    };
  },
  computed: {
    ...mapState(["showSwiper"]),
  },
  mounted() {
    this.isMobile = window.innerWidth <= 1024 ? true : false;
    // window.location.reload()
    setTimeout(() => {
      this.setCopy();
    }, 800);
  },

  methods: {
    setCopy() {
      const r_width = this.$refs.right_ref.offsetWidth;
      this.scroll1 = new gsap.timeline();
      // this.scroll2 = new gsap.timeline();
      this.scroll1.to(".scroll1", {
        duration: 15,
        repeat: -1,
        ease: "none",
        x: -r_width,
      });
      // this.scroll2.to(".scroll2", {
      //   duration: 10,
      //   repeat: -1,
      //   ease: "none",
      //   x: -r_width,
      // });
    },
    toTop() {
      // document.documentElement.scrollTop = 0;
      // 自定义一个计时器
      let timer = null;
      // window自带方法 取消窗体动画
      cancelAnimationFrame(timer);
      // 设置一个开始时间
      let startTime = new Date();
      // 得到当前body标签滚动条的高度 / 当前网页的高度
      let S = document.body.scrollTop || document.documentElement.scrollTop;
      // 停止动画的时间
      let T = 500;
      // window自带方法 requestAnimationFrame
      timer = requestAnimationFrame(function func() {
        let diff = new Date() - startTime;
        let t = T - Math.max(0, T - diff);
        // 每次回调都减掉一点点的高度，使得页面慢慢回到顶部
        document.documentElement.scrollTop = document.body.scrollTop =
          S - (t * S) / T;
        // 继续回调，继续减掉滚动条的高度
        timer = requestAnimationFrame(func);
        // 满足条件 停止回调，这个时候网页也已经回到顶部了
        if (t === T) {
          cancelAnimationFrame(timer);
        }
      });
    },
    goHome() {
      this.$router.push("/").catch((err) => err);
    },
    goLogoPage() {
      this.$router.push("/logo").catch((err) => err);
    },
  },
};
</script>
<style lang='less' scoped>
.copy {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  
  width: 95%;
  display: flex;
  font-size: 23px;

  // justify-content: space-between;
  // margin: 0 40px;

  font-weight: 700;
  z-index: 200;
  cursor: pointer;
  .textColor {
    color: #383838;
    > div,
    p {
      color: #383838;
    }
  }
  .left {
    display: flex;
    align-items: start;
    width: 45%;

    > span {
      // font-family: "HarmonyOS_Sans_TC", sans-serif;
      line-height: none;
    }
    .top {
      width: 23px;

      margin-left: 16px;
      transform: scale(1);
      transition: transform 0.5s ease-in-out;
      cursor: pointer;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
  .right-warp {
    position: relative;
    margin-left: 100px;
    width: 50%;
    .right {
      display: flex;
      white-space: nowrap;
      overflow: hidden;
      // width: 200px;
      width: 100%;
      
      .scroll-wrap {
        position: relative;
        right: 0;
        height: 60px;
        width: 100%;
      }
      .scroll1 {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(100%,0);
        // padding-right: 100px;
        // width: 100%;
        display: flex;
      }
      .scroll2 {
        display: flex;
      }
    }
    .ip {
      position: absolute;

      text-align: right;
      bottom: 0;
      right: 0;
      // transform: translateY(-100%);
      line-height: none;
      font-size: 14px;
      color: #999;
      font-weight: 400;
    }
  }
  .fontSize {
    // font-size: 23px;
    font-family: "HarmonyOS_Sans_TC", sans-serif;
    line-height: none;
  }
}

@media screen and (min-width: 1024px) {
  .copy {
    // font-size: 23px;
    bottom: 20px;
    .left {
      white-space: nowrap;
      .top {
        width: 23px;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .copy {
    font-size: 46px;
    bottom: 50px;
    .left {
      .top {
        width: 23px;
      }
    }

    .ip {
      font-size: 42px !important;
    }
    .fontSize {
      // font-size: 42px;
    }
  }
}
</style>