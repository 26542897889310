<!--  -->
<template>
  <div class="product-list" @wheel="handleWheel">
    <!-- 配 电 箱 监 视 窗 口 -->
    <div class="title">
      <p>DISTRIBUTION BOX MONITORING WINDOW (TAW80 SERIES)</p>
      <p>配 电 箱 监 视 窗 口 （ T A W 8 0 系 列 ）</p>
    </div>
    <div class="series">
      <div
        class="item-container"
        v-for="item in distributionBox"
        :key="item.index"
        @click="goProductPage(item.img)"
      >
        <div class="item reveal-bottom">
          <img
            :src="item.cover"
            alt=""
            :style="{ width: item.width, height: item.height }"
            @mouseenter="changeActive"
            @mouseleave="removeActive"
            oncontextmenu="return false;"
            class="cover"
          />

          <div
            :class="['des', item.textStyle]"
            :style="item.position ? item.position : ''"
          >
            <div class="des-title">
              <p class="des-title-text">{{ item.title }}</p>
            </div>
            <div class="des-info">
              <div class="des-info-text">
                <p v-for="infoItem in item.info" :key="infoItem">
                  {{ infoItem }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 工 业 插 座 箱 -->
    <div>
      <div class="title">
        <p>INDUSTRIAL SOCKET BOX(TDB81 SERIES)</p>
        <p>工 业 插 座 箱 （ T D B 8 1 系 列 ）</p>
      </div>
      <div class="series">
        <div
          class="item-container"
          @click="goProductPage(item.img)"
          v-for="item in industrialSocketBox"
          :key="item.index"
        >
          <div class="item reveal-bottom">
            <img
              :src="item.cover"
              alt=""
              :style="{ width: item.width, height: item.height }"
              class="cover"
              @mouseenter="changeActive"
              @mouseleave="removeActive"
              oncontextmenu="return false;"
            />

            <div
              :class="['des', item.textStyle]"
              :style="item.position ? item.position : ''"
            >
              <div class="des-title">
                <p class="des-title-text">{{ item.title }}</p>
              </div>
              <div class="des-info">
                <div class="des-info-text">
                  <p v-for="infoItem in item.info" :key="infoItem">
                    {{ infoItem }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 防水控制箱（Tdb82系列） -->
    <div>
      <div class="title">
        <p>WATERPROOF CONTROL BOX (TDB82 SERIES)</p>
        <p>防 水 控 制 箱（T D B 8 2 系 列）</p>
      </div>
      <div class="series">
        <div
          class="item-container"
          @click="goProductPage(item.img)"
          v-for="item in WaterproofControlBox"
          :key="item.index"
          :style="item.style"
        >
          <div class="item reveal-bottom">
            <img
              :src="item.cover"
              alt=""
              :style="{ width: item.width, height: item.height }"
              class="cover"
              @mouseenter="changeActive"
              @mouseleave="removeActive"
              oncontextmenu="return false;"
            />

            <div
              :class="['des', item.textStyle]"
              :style="item.position ? item.position : ''"
            >
              <div class="des-title">
                <p class="des-title-text">{{ item.title }}</p>
              </div>
              <div class="des-info">
                <div class="des-info-text">
                  <p v-for="infoItem in item.info" :key="infoItem">
                    {{ infoItem }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 防水接线盒（TDB83-86系列） -->
    <div>
      <div class="title">
        <p>WATERPROOF JUNCTION BOX (TDB83-86 SERIES)</p>
        <p>防 水 接 线 盒（T D B 8 3 - 8 6 系 列）</p>
      </div>
      <div class="series">
        <div
          class="item-container"
          v-for="item in WaterproofJunctionBox"
          :key="item.index"
          @click="goProductPage(item.img)"
          :style="item.style"
        >
          <div class="item reveal-bottom">
            <img
              :src="item.cover"
              alt=""
              :style="{ width: item.width, height: item.height }"
              class="cover"
              @mouseenter="changeActive"
              @mouseleave="removeActive"
            />

            <div
              :class="['des', item.textStyle]"
              :style="item.position ? item.position : ''"
            >
              <div class="des-title">
                <p class="des-title-text">{{ item.title }}</p>
              </div>
              <div class="des-info">
                <div class="des-info-text">
                  <p v-for="infoItem in item.info" :key="infoItem">
                    {{ infoItem }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 防水按钮盒（Tdb87系列） -->
    <div>
      <div class="title">
        <p>WATERPROOF BUTTON BOX (TDB87 SERIES)</p>
        <p>防 水 按 钮 盒（T D B 8 7 系 列）</p>
      </div>
      <div class="series">
        <div
          class="item-container"
          v-for="item in WaterproofButtonBox"
          :key="item.index"
          @click="goProductPage(item.img)"
        >
          <div class="item reveal-bottom">
            <img
              :src="item.cover"
              alt=""
              :style="{ width: item.width, height: item.height }"
              class="cover"
              @mouseenter="changeActive"
              @mouseleave="removeActive"
              oncontextmenu="return false;"
            />

            <div
              :class="['tl-des', item.textStyle]"
              :style="item.position ? item.position : ''"
            >
              <div class="tl-title">
                <p class="tl-title-text">{{ item.title }}</p>
              </div>
              <div class="des-info">
                <div class="des-info-text">
                  <p v-for="infoItem in item.info" :key="infoItem">
                    {{ infoItem }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 工业插头插座（Tdm88系列）  -->
    <div>
      <div class="title">
        <p>INDUSTRIAL PLUG AND SOCKET (TDM88 SERIES)</p>
        <p>工 业 插 头 插 座（T D M 8 8 系 列）</p>
      </div>
      <div class="series">
        <div
          class="item-container"
          v-for="item in industrialPlug"
          :key="item.index"
          @click="goProductPage(item.img)"
          style="flex: 0 0 33%; padding-bottom: 2rem"
        >
          <div class="item reveal-bottom">
            <img
              :src="item.cover"
              alt=""
              :style="{ width: item.width, height: item.height }"
              class="cover"
              @mouseenter="changeActive"
              @mouseleave="removeActive"
              oncontextmenu="return false;"
            />

            <div
              :class="['des', item.textStyle]"
              :style="item.position ? item.position : ''"
            >
              <div class="des-title">
                <p class="des-title-text">{{ item.title }}</p>
              </div>
              <div class="des-info">
                <div class="des-info-text">
                  <p v-for="infoItem in item.info" :key="infoItem">
                    {{ infoItem }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 防水配电箱（Tdb89系列） -->
    <div>
      <div class="title">
        <p>WATERPROOF DISTRIBUTION BOX (TDB89 SERIES)</p>
        <p>防 水 配 电 箱 （ T D B 8 9 系 列 ）</p>
      </div>
      <div class="series">
        <div
          class="item-container"
          v-for="item in WaterproofDistributionBox"
          :key="item.index"
          @click="goProductPage(item.img)"
        >
          <div class="item reveal-bottom">
            <img
              :src="item.cover"
              alt=""
              :style="{ width: item.width, height: item.height }"
              class="cover"
              @mouseenter="changeActive"
              @mouseleave="removeActive"
              oncontextmenu="return false;"
            />

            <div
              :class="['des', item.textStyle]"
              :style="item.position ? item.position : ''"
            >
              <div class="des-title">
                <p class="des-title-text">{{ item.title }}</p>
              </div>
              <div class="des-info">
                <div class="des-info-text">
                  <p v-for="infoItem in item.info" :key="infoItem">
                    {{ infoItem }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 明装防水插座盒（TDB90系列） -->
    <div>
      <div class="title">
        <p>EXPOSED WATERPROOF SOCKET BOX (TDB90 SERIES)</p>
        <p>明 装 防 水 插 座 盒 （T D B 9 0 系 列）</p>
      </div>
      <div class="series">
        <div
          class="item-container"
          v-for="item in exposedWaterproofBox"
          :key="item.index"
          @click="goProductPage(item.img)"
        >
          <div class="item reveal-bottom">
            <img
              :src="item.cover"
              alt=""
              :style="{ width: item.width, height: item.height }"
              class="cover"
              @mouseenter="changeActive"
              @mouseleave="removeActive"
              oncontextmenu="return false;"
            />

            <div
              :class="['des', item.textStyle]"
              :style="item.position ? item.position : ''"
            >
              <div class="des-title">
                <p class="des-title-text">{{ item.title }}</p>
              </div>
              <div class="des-info">
                <div class="des-info-text">
                  <p v-for="infoItem in item.info" :key="infoItem">
                    {{ infoItem }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 配件 -->
    <div>
      <div class="title">
        <p>ACCESSORIES</p>
        <p>配 件</p>
      </div>
      <div class="acce-series">
        <div
          class="s-item-container"
          @click="goProductPage(accessories[0].img)"
        >
          <div class="item w90 reveal-bottom">
            <img
              :src="accessories[0].cover"
              alt=""
              :style="{
                width: accessories[0].width,
                height: accessories[0].height,
              }"
              class="cover"
              @mouseenter="changeActive"
              @mouseleave="removeActive"
              oncontextmenu="return false;"
            />

            <div
              :class="['des', accessories[0].textStyle]"
              :style="accessories[0].position ? accessories[0].position : ''"
            >
              <div class="des-title">
                <p class="des-title-text">{{ accessories[0].title }}</p>
              </div>
              <div class="des-info">
                <div class="des-info-text">
                  <p v-for="infoItem in accessories[0].info" :key="infoItem">
                    {{ infoItem }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="main-item-container"
          @click="goProductPage(accessories[1].img)"
        >
          <div class="item w90 reveal-bottom">
            <img
              :src="accessories[1].cover"
              alt=""
              :style="{
                width: accessories[1].width,
                height: accessories[1].height,
              }"
              class="cover"
              @mouseenter="changeActive"
              @mouseleave="removeActive"
              oncontextmenu="return false;"
            />

            <div
              :class="['des', accessories[1].textStyle]"
              :style="accessories[1].position ? accessories[1].position : ''"
            >
              <div class="des-title">
                <p class="des-title-text">{{ accessories[1].title }}</p>
              </div>
              <div class="des-info">
                <div class="des-info-text">
                  <p v-for="infoItem in accessories[1].info" :key="infoItem">
                    {{ infoItem }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import scrollReveal from "scrollreveal";
export default {
  data() {
    return {
      scrollReveal: null,
      scrollTop: 0,
      isMobile: 0,
      //配 电 箱 监 视 窗 口
      distributionBox: [
        {
          cover: require("../assets/image/product/peidianxiang1.png"),
          title: "德式防水窗口",
          info: ["A型：不带保护", "B型：带保护"],
          textStyle: "br",
          position: "",
          width: "15.2rem",
          height: "6.4rem",
          index: 0,
          isEmpty: false,
          img: "peidianxiang1",
        },
        {
          cover: require("../assets/image/product/peidianxiang2.png"),
          title: "豪华防水窗口",
          info: ["C型：带挡板", "D型：不带挡板", "S型：带锁扣"],
          textStyle: "bl",
          position: "left: 2px",
          width: "8rem",
          height: "9.06rem",
          index: 1,
          isEmpty: false,
          img: "peidianxiang2",
        },
        {
          cover: require("../assets/image/product/peidianxiang3.png"),
          title: "电力仪表窗口",
          info: [],
          textStyle: "bc",
          width: "8rem",
          height: "9.06rem",
          index: 2,
          isEmpty: false,
          img: "peidianxiang3",
        },
        {
          cover: require("../assets/image/product/peidianxiang4.png"),
          title: "塑壳空开窗口",
          info: [],
          textStyle: "bc",
          width: "8rem",
          height: "9.06rem",
          index: 3,
          isEmpty: false,
          img: "peidianxiang4",
        },
        {
          cover: require("../assets/image/product/peidianxiang5.png"),
          title: "86型防水窗口",
          info: [],
          textStyle: "bc",
          width: "7.31rem",
          height: "9.31rem",
          index: 4,
          isEmpty: false,
          img: "peidianxiang5",
        },
      ],
      //工 业 插 座 箱
      industrialSocketBox: [
        {
          cover: require("../assets/image/product/gongyechazuoxiang1.png"),
          title: "螺栓插座箱",
          info: ["螺栓型", "拼装型"],
          textStyle: "bl",
          position: "left: 3px;",
          width: "9.19rem",
          height: "13.62rem",
          index: 0,
          isEmpty: true,
          img: "gongyechazuoxiang1",
        },
        {
          cover: require("../assets/image/product/gongyechazuoxiang2.png"),
          title: "合页插座箱",
          info: [],
          textStyle: "bc",
          width: "10rem",
          height: "13.69rem",
          index: 1,
          isEmpty: true,
          img: "gongyechazuoxiang2",
        },
        {
          cover: require("../assets/image/product/gongyechazuoxiang3.png"),
          title: "阶梯插座箱",
          info: [],
          textStyle: "bc",
          width: "11.25rem",
          height: "14.38rem",
          index: 2,
          isEmpty: true,
          img: "gongyechazuoxiang3",
        },
        {
          cover: require("../assets/image/product/gongyechazuoxiang4.png"),
          title: "手提插座箱",
          info: [],
          textStyle: "bc",
          width: "13.63rem",
          height: "13.36rem",
          index: 3,
          isEmpty: true,
          img: "gongyechazuoxiang4",
        },
        {
          cover: require("../assets/image/product/gongyechazuoxiang5.png"),
          title: "斜面插座箱",
          info: [],
          textStyle: "bc",
          width: "9.44rem",
          height: "12.75rem",
          index: 4,
          isEmpty: true,
          img: "gongyechazuoxiang5",
        },
        {
          cover: require("../assets/image/product/gongyechazuoxiang6.png"),
          title: "电源检修箱",
          info: [
            "手提插座箱",
            "壁挂插座箱",
            "拼装插座箱",
            "阶梯插座箱",
            "移动插座箱",
            "金属插座箱",
            "吊装插座箱",
          ],
          textStyle: "lr",
          position: "top: -4px;",
          width: "10.88rem",
          height: "15rem",
          index: 5,
          isEmpty: false,
          img: "gongyechazuoxiang6",
        },
      ],
      //防水控制箱（Tdb82系列）
      WaterproofControlBox: [
        {
          cover: require("../assets/image/product/fangshuikongzhixiang1.png"),
          title: "螺栓控制箱",
          info: ["G：灰色盖", "T：透明盖"],
          position: "right: 5px;",
          textStyle: "br",
          width: "9.31rem",
          height: "13.75rem",
          index: 0,
          isEmpty: false,
          img: "fangshuikongzhixiang1",
        },
        {
          cover: require("../assets/image/product/fangshuikongzhixiang2.png"),
          title: "合页控制箱",
          info: ["带锁型", "搭扣型"],
          textStyle: "br",
          position: "right: -4px;",
          width: "15.5rem",
          height: "18.75rem",
          index: 1,
          isEmpty: false,
          img: "fangshuikongzhixiang2",
        },
        {
          cover: require("../assets/image/product/fangshuikongzhixiang3.png"),
          title: "中门控制箱",
          info: ["带锁型", "搭扣型"],
          textStyle: "br",
          position: "right: 2px;",
          width: "9.81rem",
          height: "13.62rem",
          index: 2,
          isEmpty: false,
          img: "fangshuikongzhixiang3",
        },
        {
          cover: require("../assets/image/product/fangshuikongzhixiang4.png"),
          title: "工地配电箱",
          info: [],
          textStyle: "bc",
          position: "left: 54%;bottom: 20px;",
          width: "13.19rem",
          height: "18.75rem",
          index: 3,
          isEmpty: false,
          img: "fangshuikongzhixiang4",
        },
        {
          cover: require("../assets/image/product/fangshuikongzhixiang5.png"),
          title: "拼装控制箱",
          info: ["G：灰色盖", "T：透明盖"],
          style: "flex: 0 0 50%;",
          textStyle: "lr",
          position: "top: 14px; right: -10px;",
          width: "28.5rem",
          height: "18.75rem",
          index: 4,
          isEmpty: false,
          img: "fangshuikongzhixiang5",
        },
      ],
      //防水接线盒（TDB83-86系列）
      WaterproofJunctionBox: [
        {
          cover: require("../assets/image/product/fangshuijiexianhe1.png"),
          title: "AG型接线盒",
          info: ["G：灰色盖", "T：透明盖"],
          textStyle: "bl",
          position: "left: 6px;",

          width: "4.19rem",
          height: "12.69rem",
          index: 0,
          isEmpty: false,
          img: "fangshuijiexianhe1",
        },
        {
          cover: require("../assets/image/product/fangshuijiexianhe2.png"),
          title: "F型接线盒",
          info: ["带耳固定", "不带耳固定"],
          textStyle: "br",
          position: "right: 1px;",

          width: "10.56rem",
          height: "10.56rem",
          index: 1,
          isEmpty: false,
          img: "fangshuijiexianhe2",
        },
        {
          cover: require("../assets/image/product/fangshuijiexianhe3.png"),
          title: "经济型接线盒",
          info: ["带塞", "不带塞"],
          textStyle: "br",
          width: "11.19rem",
          height: "11.19rem",
          index: 2,
          isEmpty: false,
          img: "fangshuijiexianhe3",
        },
        {
          cover: require("../assets/image/product/fangshuijiexianhe4.png"),
          title: "一体端子盒",
          info: [],
          textStyle: "bc",
          position: "bottom: 20px;",
          width: "7.13rem",
          height: "13.16rem",
          index: 3,
          isEmpty: false,
          img: "fangshuijiexianhe4",
        },
        {
          cover: require("../assets/image/product/fangshuijiexianhe5.png"),
          title: "分线端子盒",
          info: [],
          style: "flex: 0 0 50%;",
          textStyle: "fx",
          width: "33.13rem",
          height: "8.5rem",
          index: 4,
          isEmpty: false,
          img: "fangshuijiexianhe5",
        },
        {
          cover: require("../assets/image/product/fangshuijiexianhe6.png"),
          title: "合页接线盒",
          info: ["不锈钢搭扣", "塑料搭扣"],
          textStyle: "br",
          width: "16rem",
          height: "15rem",
          index: 5,
          isEmpty: false,
          img: "fangshuijiexianhe6",
        },
        {
          cover: require("../assets/image/product/fangshuijiexianhe7.png"),
          title: "铸铝接线盒",
          info: [],
          textStyle: "bc",
          position: "bottom: 20px;",
          width: "6.9rem",
          height: "15rem",
          index: 6,
          isEmpty: false,
          img: "fangshuijiexianhe7",
        },
      ],
      //防水按钮盒
      WaterproofButtonBox: [
        {
          cover: require("../assets/image/product/fangshuianniuhe1.png"),
          title: "施耐德按钮盒",
          info: [],
          textStyle: "tr",
          width: "6.31rem",
          height: "15.13rem",
          index: 0,
          isEmpty: false,
          img: "fangshuianniuhe1",
        },
        {
          cover: require("../assets/image/product/fangshuianniuhe2.png"),
          title: "经济型按钮盒",
          info: [],
          textStyle: "tr",
          width: "6rem",
          height: "12.5rem",
          index: 1,
          isEmpty: false,
          img: "fangshuianniuhe2",
        },
        {
          cover: require("../assets/image/product/fangshuianniuhe3.png"),
          title: "AG型按钮盒",
          info: [],
          textStyle: "tr",
          position: "top: -5px;right: -15px;",
          width: "13.19rem",
          height: "17.5rem",
          index: 2,
          isEmpty: false,
          img: "fangshuianniuhe3",
        },
        {
          cover: require("../assets/image/product/fangshuianniuhe4.png"),
          title: "带防尘罩按钮盒",
          info: [],
          textStyle: "tr",
          width: "7.06rem",
          height: "12.81rem",
          index: 3,
          isEmpty: false,
          img: "fangshuianniuhe4",
        },
        {
          cover: require("../assets/image/product/fangshuianniuhe5.png"),
          title: "铸铝按钮盒",
          info: [],
          textStyle: "tr",
          width: "7.38rem",
          height: "15.88rem",
          index: 4,
          isEmpty: false,
          img: "fangshuianniuhe5",
        },
        {
          cover: require("../assets/image/product/fangshuianniuhe6.png"),
          title: "高桥按钮盒",
          info: [],
          textStyle: "tr",
          width: "6.63rem",
          height: "17.5rem",
          index: 5,
          isEmpty: false,
          img: "fangshuianniuhe6",
        },
        {
          cover: require("../assets/image/product/fangshuianniuhe7.png"),
          title: "带灯按钮盒",
          info: [],
          textStyle: "tr",
          width: "13.44rem",
          height: "10.81rem",
          index: 6,
          isEmpty: false,
          img: "fangshuianniuhe7",
        },
      ],
      //工业插头插座
      industrialPlug: [
        {
          cover: require("../assets/image/product/gongyechatou1.png"),
          title: "德式型",
          info: [
            "插头",
            "明装插座",
            "连接器(耦合器)",
            "直座",
            "斜座",
            "进线插座",
          ],
          textStyle: "bsb",
          position: "left: -7px;width: 103%;",
          width: "19.13rem",
          height: "10.81rem",
          index: 0,
          isEmpty: false,
          img: "gongyechatou1",
        },
        {
          cover: require("../assets/image/product/gongyechatou2.png"),
          title: "经济型",
          info: [
            "插头",
            "明装插座",
            "连接器(耦合器)",
            "直座",
            "斜座",
            "进线插座",
          ],
          textStyle: "bsb",
          position: "left: -7px;width: 103%;",
          width: "19.38rem",
          height: "7.88rem",
          index: 1,
          isEmpty: false,
          img: "gongyechatou2",
        },
        {
          cover: require("../assets/image/product/gongyechatou3.png"),
          title: "国标型",
          info: [],
          textStyle: "bc",
          width: "6.13rem",
          height: "7.88rem",
          index: 2,
          isEmpty: false,
          img: "gongyechatou3",
        },
        {
          cover: require("../assets/image/product/gongyechatou4.png"),
          title: "欧标型",
          info: [],
          textStyle: "bc",
          width: "6.13rem",
          height: "7.75rem",
          index: 3,
          isEmpty: false,
          img: "gongyechatou4",
        },
      ],
      //防水配电箱
      WaterproofDistributionBox: [
        {
          cover: require("../assets/image/product/fangshuipeidianxiang1.png"),
          title: "TD-AG系列",
          info: ["户外配电箱"],
          textStyle: "br",
          width: "11.13rem",
          height: "6.69rem",
          index: 0,
          isEmpty: false,
          img: "fangshuipeidianxiang1",
        },
        {
          cover: require("../assets/image/product/fangshuipeidianxiang2.png"),
          title: "TD-HA系列",
          info: ["户外配电箱"],
          textStyle: "br",
          width: "12.69rem",
          height: "11.06rem",
          index: 1,
          isEmpty: false,
          img: "fangshuipeidianxiang2",
        },
        {
          cover: require("../assets/image/product/fangshuipeidianxiang3.png"),
          title: "TD-HT系列",
          info: ["户外配电箱"],
          textStyle: "br",
          width: "14.38rem",
          height: "11.19rem",
          index: 2,
          isEmpty: false,
          img: "fangshuipeidianxiang3",
        },
        {
          cover: require("../assets/image/product/fangshuipeidianxiang4.png"),
          title: "TD-MCE系列",
          info: ["户外配电箱"],
          textStyle: "br",
          width: "9.5rem",
          height: "11.19rem",
          index: 3,
          isEmpty: false,
          img: "fangshuipeidianxiang4",
        },
        {
          cover: require("../assets/image/product/fangshuipeidianxiang5.png"),
          title: "TD-EX系列(防爆配电箱)",
          info: ["户外配电箱"],
          textStyle: "bl",
          width: "14.75rem",
          height: "9.56rem",
          index: 4,
          isEmpty: false,
          img: "fangshuipeidianxiang5",
        },
        {
          cover: require("../assets/image/product/fangshuipeidianxiang6.png"),
          title: "室内配电箱",
          info: [],
          textStyle: "bc",
          position: "bottom: 20px;",
          width: "12.69rem",
          height: "12.56rem",
          index: 5,
          isEmpty: false,
          img: "fangshuipeidianxiang6",
        },
      ],
      //明装防水插座盒
      exposedWaterproofBox: [
        {
          cover: require("../assets/image/product/mingzhuangfangshuichazuo1.png"),
          title: "IP44插座盒",
          info: [],
          textStyle: "bc",
          position: "bottom: 20px;left: 55%;",
          width: "11.81rem",
          height: "6.88rem",
          index: 0,
          isEmpty: false,
          img: "mingzhuangfangshuichazuo1",
        },
        {
          cover: require("../assets/image/product/mingzhuangfangshuichazuo2.png"),
          title: "IP55插座盒",
          info: [],
          textStyle: "bc",
          width: "10rem",
          height: "12.25rem",
          index: 1,
          isEmpty: false,
          img: "mingzhuangfangshuichazuo2",
        },
        {
          cover: require("../assets/image/product/mingzhuangfangshuichazuo3.png"),
          title: "IP66插座盒",
          info: [],
          textStyle: "bc",
          width: "12.13rem",
          height: "14.06rem",
          index: 2,
          isEmpty: false,
          img: "mingzhuangfangshuichazuo3",
        },
        {
          cover: require("../assets/image/product/mingzhuangfangshuichazuo4.png"),
          title: "86型插座盒",
          info: ["A款", "B款"],
          textStyle: "br",
          width: "9.94rem",
          height: "12.75rem",
          index: 3,
          isEmpty: false,
          img: "mingzhuangfangshuichazuo4",
        },
      ],
      //配件
      accessories: [
        {
          cover: require("../assets/image/product/peijian1.png"),
          title: "电缆防水接头",
          info: [],
          textStyle: "",
          position: "top: 20px;left: 60px;",
          width: "25.94rem",
          height: "11.25rem",
          index: 0,
          isEmpty: false,
          img: "peijian1",
        },
        {
          cover: require("../assets/image/product/peijian2.png"),
          title: "更多配件",
          info: [
            "挂角",
            "防水塞头",
            "电缆接头",
            "塑料钥匙",
            "导轨",
            "窗口支架",
            "C45挡板",
            "蜂窝版",
            "接线端子",
          ],
          textStyle: "",
          position: "top: -5px",
          width: "",
          height: "",
          index: 1,
          isEmpty: false,
          img: "peijian2",
        },
      ],
    };
  },
  created() {
    this.scrollReveal = scrollReveal();
  },
  activated() {},
  mounted() {
    // this.$wow.init()
    setTimeout(() => {
      let doms = document.getElementsByClassName("reveal-bottom");

      // 获取页面底部距离窗口顶部的位置
      const pageBottom = window.innerHeight + window.scrollY;
      //筛选出还未显示在页面中的商品Dom
      let arr = [];
      for (let i = 0; i < doms.length; i++) {
        let containerTop = doms[i].getBoundingClientRect().top + window.scrollY;
        if (containerTop > pageBottom) {
          arr.push(doms[i]);
        }
      }
      this.scrollReveal.reveal(arr, {
        // 动画的时长
        duration: 1000,
        // 延迟时间
        delay: 0,
        // 动画开始的位置，'bottom', 'left', 'top', 'right'
        origin: "bottom",
        // 回滚的时候是否再次触发动画
        reset: false,
        // 在移动端是否使用动画
        mobile: true,
        // 滚动的距离，单位可以用%，rem等
        distance: "50px",
        // 其他可用的动画效果
        opacity: 0.5,
        easing: "linear",
        // afterReveal: ()=>{
        //   console.log('动画结束');
        // },
        // viewFactor: 0.5,
        // cleanUp: true,
      });
      this.isMobile = localStorage.getItem("isMobile");
      if (this.isMobile) {
      }
    }, 200);
  },
  methods: {
    handleWheel(event) {
      // let doms = document.getElementsByClassName('reveal-bottom');
      // console.log(doms);
      // // this.scrollReveal.destroy()
      // if (event.deltaY > 0) {
      //   console.log("鼠标滚轮下滑:", event);
      // } else {
      //   console.log("鼠标滚轮上滑:", event);
      // }
    },
    goProductPage(img) {
      this.$router.push(`/product?url=${img}`).catch((err) => err);
      
    }, 
    changeActive(e) {
      // console.log(11111);
      let currentClassName = e.target.parentElement.className;
      // return;
      if (
        currentClassName === "item" ||
        currentClassName.indexOf("item") != -1
      ) {
        e.target.parentElement.className = "item active";
      } else {
        e.target.className = "item active";
      }

      this.$emit("changeActive", true);
      // console.log(e);
    },
    removeActive(e) {
      this.$emit("changeActive", false);
      setTimeout(() => {
        if (e.target && e.target.className) {
          e.target.parentElement.className = "item";
        }
      }, 200);
    },
  },
};
</script>
<style lang='less' scoped>
img {
    // pointer-events:none;
  }
.product-list {
  overflow: hidden;
  padding: 100px 40px 20px;
}
.active {
  z-index: 99 !important;
}
.title {
  padding-left: 22px;
  font-size: 18px;
}
.series {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;

  .item-container {
    flex: 0 0 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
    .item {
      position: relative;
      padding: 10px;
      .cover {
        display: block;
        transform: scale(1);
        transition: transform 0.5s ease-in-out;
        &:hover {
          transform: scale(1.1);
          ~ .des {
            .des-info {
              -webkit-mask-image: linear-gradient(
                to top,
                rgba(0, 0, 0, 1) calc(100%),
                transparent
              );
              mask-image: linear-gradient(
                to top,
                rgba(0, 0, 0, 1) calc(100%),
                transparent
              );
            }
            .des-title-text,
            .des-info-text {
              opacity: 1;
            }
            .des-info-text {
              transform: translateY(0);
            }
          }
          ~ .tl-des {
            .tl-title-text,
            .tl-info-text {
              opacity: 1;
            }
            .tl-info-text {
              transform: translateY(0);
            }
          }
        }
      }
      .des {
        position: absolute;
        bottom: 30px;
        .des-title {
          width: 100%;
          height: 35px;
          overflow: hidden;
          .des-title-text {
            opacity: 0;
            transition: opacity 0.8s ease-in-out;
            font-size: 20px;
          }
        }
        .des-info {
          height: 105px;
          overflow: hidden;
          // background-color: pink;
          -webkit-mask-image: linear-gradient(
            to top,
            rgba(0, 0, 0, 1) calc(100% - 1em),
            transparent
          );
          mask-image: linear-gradient(
            to top,
            rgba(0, 0, 0, 1) calc(100% - 1em),
            transparent
          );
          transition: mask-image 0.5s ease-in-out;
          .des-info-text {
            position: relative;
            top: 0;
            left: 0;
            right: 0;
            transform: translateY(-100%);
            opacity: 0;
            transition: transform 0.5s ease-in-out, opacity 0.8s ease-in-out;
            font-size: 12px;
            font-weight: 700;
            line-height: 1.2;
          }
        }
      }
      //产品顶部靠右标题
      .tl-des {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(140%);
        .tl-title {
          height: 100%;
          width: 25px;
          overflow: hidden;
          .tl-title-text {
            opacity: 0;
            transition: transform 0.5s ease-in-out, opacity 0.8s ease-in-out;
            font-size: 20px;
            line-height: 1;
          }
        }
      }
    }
  }
}

.acce-series {
  position: relative;
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-between;

  .main-item-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
    .item {
      width: 90%;
      position: relative;
      padding: 20px;
      .cover {
        width: 100%;
        transform: scale(1);
        transition: transform 0.5s ease-in-out;
        &:hover {
          transform: scale(1.1);
          ~ .des {
            .des-info {
              -webkit-mask-image: linear-gradient(
                to top,
                rgba(0, 0, 0, 1) calc(100%),
                transparent
              );
              mask-image: linear-gradient(
                to top,
                rgba(0, 0, 0, 1) calc(100%),
                transparent
              );
            }
            .des-title-text,
            .des-info-text {
              opacity: 1;
            }
            .des-info-text {
              transform: translateY(0);
            }
          }
          ~ .tl-des {
            .tl-title-text,
            .tl-info-text {
              opacity: 1;
            }
            .tl-info-text {
              transform: translateY(0);
            }
          }
        }
      }
      .des {
        position: absolute;
        right: 0;
        top: 0;
        .des-title {
          width: 100%;
          height: 35px;
          overflow: hidden;
          .des-title-text {
            opacity: 0;
            transition: opacity 0.8s ease-in-out;
            font-size: 20px;
          }
        }
        .des-info {
          height: 135px;
          overflow: hidden;
          -webkit-mask-image: linear-gradient(
            to top,
            rgba(0, 0, 0, 1) calc(100% - 1em),
            transparent
          );
          mask-image: linear-gradient(
            to top,
            rgba(0, 0, 0, 1) calc(100% - 1em),
            transparent
          );
          .des-info-text {
            position: relative;
            top: 0;
            left: 0;
            right: 0;
            transform: translateY(-100%);
            opacity: 0;
            transition: transform 0.5s ease-in-out, opacity 0.8s ease-in-out;
            font-size: 12px;
            font-weight: 700;
            line-height: 1.2;
          }
        }
      }
      //产品顶部靠右标题
      .tl-des {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(140%);
        .tl-title {
          height: 100%;
          width: 25px;
          overflow: hidden;
          .tl-title-text {
            opacity: 0;
            transition: transform 0.5s ease-in-out, opacity 0.8s ease-in-out;
            font-size: 20px;
            line-height: 1;
          }
        }
      }
    }
  }
  .s-item-container {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;

    .item {
      position: relative;
      padding: 20px 40px;
      z-index: 2;
      .cover {
        transform: scale(1);
        transition: transform 0.5s ease-in-out;
        &:hover {
          transform: scale(1.1);
          ~ .des {
            .des-info {
              -webkit-mask-image: linear-gradient(
                to top,
                rgba(0, 0, 0, 1) calc(100%),
                transparent
              );
              mask-image: linear-gradient(
                to top,
                rgba(0, 0, 0, 1) calc(100%),
                transparent
              );
            }
            .des-title-text,
            .des-info-text {
              opacity: 1;
            }
            .des-info-text {
              transform: translateY(0);
            }
          }
          ~ .tl-des {
            .tl-title-text,
            .tl-info-text {
              opacity: 1;
            }
            .tl-info-text {
              transform: translateY(0);
            }
          }
        }
      }
      .des {
        position: absolute;
        // left: 40px;
        // top: 20px;
        transform: translateX(100%);
        .des-title {
          width: 100%;
          height: 35px;
          overflow: hidden;
          .des-title-text {
            opacity: 0;
            transition: opacity 0.8s ease-in-out;
            font-size: 20px;
          }
        }
        .des-info {
          height: 135px;
          overflow: hidden;
          -webkit-mask-image: linear-gradient(
            to top,
            rgba(0, 0, 0, 1) calc(100% - 1em),
            transparent
          );
          mask-image: linear-gradient(
            to top,
            rgba(0, 0, 0, 1) calc(100% - 1em),
            transparent
          );
          .des-info-text {
            position: relative;
            top: 0;
            left: 0;
            right: 0;
            transform: translateY(-100%);
            opacity: 0;
            transition: transform 0.5s ease-in-out, opacity 0.8s ease-in-out;
            font-size: 12px;
            font-weight: 700;
            line-height: 1.2;
          }
        }
      }
      //产品顶部靠右标题
      .tl-des {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(140%);
        .tl-title {
          height: 100%;
          width: 25px;
          overflow: hidden;
          .tl-title-text {
            opacity: 0;
            transition: transform 0.5s ease-in-out, opacity 0.8s ease-in-out;
            font-size: 20px;
            line-height: 1;
          }
        }
      }
    }
  }
}
//br - bottom right
.br {
  right: 0;
  bottom: 0;
  transform: translateY(100% + 27px);
  text-align: left;
}
//bl - bottom left
.bl {
  left: 0;
  // bottom: -10px;
  white-space: nowrap;
  transform: translateY(100% + 27px);
  text-align: left;
}
//bl - bottom center
.bc {
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 100% + 23px);
  width: 100%;
  white-space: nowrap;
  text-align: center;
}
//lr - left text-right
.lr {
  right: 0;
  top: 0;
  transform: translate(120%, 0);
  // width: 100%;
  text-align: right;
}
//分线端子盒专用
.fx {
  left: -20px;
  bottom: 0 !important;
  transform: translate(0, 80%);
}
//bsb bottom space-between
.bsb {
  // position: absolute;
  bottom: 0 !important;
  transform: translateY(100%);
  // left: 0;

  display: flex;
  justify-content: space-between;
  .des-info {
    min-width: 60%;
    text-align: right;
    white-space: nowrap;
  }
}
@media screen and (min-width: 1025px) {
  .title {
    font-size: 18px;
  }
  .product-list {
    padding-top: 100px;
  }
  .series {
    .item-container {
      flex: 0 0 25%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .title {
    font-size: 48px;
  }
  .product-list {
    padding-top: 160px;
  }
  .series {
    .item-container {
      flex: 0 0 33%;
    }
  }
}
</style>