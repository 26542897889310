<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  created(){
    let isMobile = window.innerWidth <= 1024 ? true : false;
      this.$store.commit("setIsMobile", isMobile);
  },
  methods: {
  }
}
</script>

<style>

</style>
