<!--  -->
<template>
  <div>
    <div class="l-logo" @click="logo1Handler">
      <img src="../assets/logo1.png" alt="" class="logo1" ref="logo1_ref" />
      <img src="../assets/logo1_white.png" alt="" class="logo1_white" />
    </div>

    <div class="r-logo" @click="logo2Handler">
      <img
        src="../assets/logo2.png"
        alt=""
        class="logo2"
        @mouseenter.stop="changeLogo(0)"
        v-show="logoStatus == 1"
        ref="logo2_ref"
      />
      <img
        src="../assets/logo2_white.png"
        alt=""
        class="logo2_white"
        v-show="logoStatus == 1"
      />

      <span
        class="logo-text"
        @mouseleave="changeLogo(1)"
         v-show="logoStatus == 0"
        >公司介绍</span
      >
    </div>

    <div class="banner">
      <img
        :src="item"
        alt=""
        :class="['water_bg', `bg${index}`]"
        v-for="(item, index) in bgList"
        :key="index"
        @click="nextBg(index)"
      />
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";

import { mapState } from "vuex";
export default {
  data() {
    return {
      logoStatus: 1,
      showInfo: false,
      logo1_X: null,
      logo1_Y: null,
      tl1: null,
      tl2: null,
      banner: null,
      bg: null,
      bgList: [],
      bgIndex: 0,
      timer: null,
    };
  },
  computed: {
    ...mapState(["showSwiper"]),
  },
  created() {},
  mounted() {
    setTimeout(() => {
      this.getAxis();
    }, 200);

    window.addEventListener("resize", this.getAxis);
  },
  destroyed() {
    window.removeEventListener("resize", this.getAxis);
  },
  methods: {
    logo1Handler() {
      let route = this.$route.name;
      if (route === "home") {
        this.changeInfo();
      } else {
        console.log(111);
        this.$router.push("/").catch((err) => err);
      }
    },
    logo2Handler() {
      let route = this.$route.name;
      if (this.showInfo && route === "home") {
        this.changeInfo();
      } else {
        this.goIntroduce();
      }
    },
    changeLogo(status) {
      let logo2 = this.$refs.logo2_ref.getBoundingClientRect();
      if (this.showInfo || this.isMobile || logo2.top > 100) return;
      this.logoStatus = status;
    },
    goIntroduce() {
      if (this.showInfo) return;

      this.$router.push("/introduce").catch((err) => err);
    },
    changeInfo() {
      this.showInfo = !this.showInfo;
      this.$store.commit("setShowSwiper", this.showInfo);
      const duration = 0.3;
      const delay = 1;

      if (this.showInfo) {
        this.$emit("hideList", this.showInfo);
        this.tl1 = new gsap.timeline();
        this.tl2 = new gsap.timeline();
        this.banner = new gsap.timeline();

        this.tl1
          .to(".l-logo", {
            x: this.logo1_X,
            y: this.logo1_Y,
          })

          .to(".logo1", {
            delay: delay,
            duration: duration,
            opacity: 0,
          })
          .to(".logo1", {
            display: "none",
          })
          .to(".logo1_white", {
            opacity: 1,
            duration: duration,
          });

        this.tl2
          .to(".r-logo", {
            x: this.logo2_X,
            y: this.logo2_Y,
          })
          .to(".logo2", {
            delay: delay,
            duration: duration,
            opacity: 0,
          })
          .to(".logo2", {
            display: "none",
          })
          .to(".logo2_white", {
            opacity: 1,
            duration: duration,
          });

        this.banner
          .to(".banner", {
            delay: delay + duration * 2 - 0.2,
            display: "block",
            ease: 'none'
          })
          .to(".banner", {
            backgroundColor: "#000000",
            ease: 'none'
          });

        this.showBg(delay + duration * 2 - 0.2);
      } else {
        this.banner.reverse();
        this.tl1.reverse();
        this.tl2.reverse();
        
        clearInterval(this.timer);
        this.bgIndex = 0;
        this.bg.to(".water_bg", {
          opacity: 0,
          delay: 0.5,
        });
        this.$emit("hideList", this.showInfo,delay + duration * 2 - 0.2);
      }
    },
    showBg(delay) {
      this.bg = new gsap.timeline();

      this.bg.to(`.bg${this.bgIndex}`, {
        delay: delay + 0.5,
        duration: 0.3,
        opacity: 1,
        zIndex: 4 - this.bgIndex + 5,
      });
      this.swiperBg();
    },
    swiperBg() {
      this.timer = setInterval(() => {
        this.bg.to(`.bg${this.bgIndex}`, {
          delay: 3,
          duration: 0.3,
          opacity: 0,
          zIndex: 4 - this.bgIndex - 5,
        });
        if (this.bgIndex <= 3) {
          this.bgIndex++;
        } else {
          this.bgIndex = 0;
        }
        this.bg.to(`.bg${this.bgIndex}`, {
          duration: 0.3,
          opacity: 1,
          zIndex: 4 - this.bgIndex + 5,
        });
      }, 4000);
    },
    nextBg(index) {
      clearInterval(this.timer);
      this.bg.kill();
      this.bg = null;
      this.bg = new gsap.timeline();
      this.bgIndex = index + 1 >= 5 ? 0 : index + 1;
      this.bg.to(`.bg${index}`, {
        duration: 0.3,
        opacity: 0,
        zIndex: 4 - index - 5,
      });

      this.bg.to(`.bg${this.bgIndex}`, {
        duration: 0.3,
        opacity: 1,
        zIndex: 4 - this.bgIndex + 5,
      });
      this.swiperBg();
    },
    //获取要移动到的中心位置坐标
    getAxis() {
      this.isMobile = window.innerWidth <= 1024 ? true : false;
      if (this.isMobile) {
        this.bgList = [
          require("../assets/image/waterBgImg/mobile1.png"),
          require("../assets/image/waterBgImg/mobile2.png"),
          require("../assets/image/waterBgImg/mobile3.png"),
          require("../assets/image/waterBgImg/mobile4.png"),
          require("../assets/image/waterBgImg/mobile5.png"),
        ];
      } else {
        this.bgList = [
          require("../assets/image/waterBgImg/1.png"),
          require("../assets/image/waterBgImg/2.png"),
          require("../assets/image/waterBgImg/3.png"),
          require("../assets/image/waterBgImg/4.png"),
          require("../assets/image/waterBgImg/5.png"),
        ];
      }
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const logo1 = this.$refs.logo1_ref.getBoundingClientRect();
      const logo2 = this.$refs.logo2_ref.getBoundingClientRect();
      //获取两个元素加起来的宽度
      const width = logo1.width + logo2.width;
      //获取两个元素加起来的宽度的左右两侧在页面中心的位置
      const right_X = windowWidth / 2 + width / 2;
      const left_X = windowWidth / 2 - width / 2;

      this.logo1_X = left_X - logo1.left + logo1.width + 10;
      this.logo2_X = windowWidth - logo2.right + logo2.width - right_X;
      if(this.isMobile){
        this.logo1_Y = windowHeight * 0.5 - logo1.height;
        this.logo2_Y = windowHeight * 0.5 - logo2.height + 5;
      }else {
        this.logo1_Y = windowHeight * 0.5 - logo1.height;
        this.logo2_Y = windowHeight * 0.5 - logo2.height + 10;
      }
      
    },
  },
};
</script>
<style lang='less' scoped>
.l-logo {
  position: absolute;
  
  left: 32px;
  top: 0;
  z-index: 10;
  cursor: pointer;
  img {
     position: absolute;
    // left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
.r-logo {
  position: absolute;
  left: 70%;
  top: 0;
  cursor: pointer;
  z-index: 10;
  img {
    position: absolute;
    // left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .logo-text {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    font-weight: 500;
    min-width: 200px;
  }
  .logo2 {
    z-index: 2;
  }
}
.logo1_white,
.logo2_white {
  opacity: 0;
}
.banner {
  position: relative;
  // width: 100vw;
  height: calc(100vh + 1px);
  display: none;
  background-color: #fff;
  .water_bg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: auto;
    opacity: 0;
  }
  .bg0 {
    z-index: 4;
  }
  .bg1 {
    z-index: 3;
  }
  .bg2 {
    z-index: 2;
  }
  .bg3 {
    z-index: 1;
  }
  .bg4 {
    z-index: 0;
  }
}

@media screen and (min-width: 1025px) {
  .l-logo {
    height: 100px;
    > img {
      height: 37px;
    }
  }
  .r-logo {
    height: 100px;
    // right: 277px;
    // left: 70%;
    > img {
      height: 44px;
      // left: 80%;
    }
    .logo-text {
      font-size: 40px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .l-logo {
    height: 218px;
    > img {
      height: 94px;
    }
  }
  .r-logo {
    right: 30px;
    height: 218px;
    > img {
      height: 114px;
      right: 30px;
    }
    // .logo-text {
    //   font-size: 80px;
    // }
  }
}
</style>